<template>
    <div class="page">
        <div class="page_header">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>短视频</el-breadcrumb-item>
                <el-breadcrumb-item>推广套餐</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="page_headerSecondary">
            <div></div>
            <div class="_add">
                <button @click="openAdd">添加</button>
            </div>
        </div>
        <!-- 列表 -->
        <div class="page_content">
            <el-table :data="tableData" border>
                <el-table-column prop="title" align="center" label="标题"></el-table-column>
                <el-table-column prop="title" align="center" label="背景图">
                    <template slot-scope="{ row }">
                        <img :src="row.bg_image" alt="" style="width: 100%" />
                    </template>
                </el-table-column>
                <el-table-column prop="status" align="center" label="状态">
                    <template slot-scope="{ row }">
                        <span :style="{ color: row.status ? '#b66dff' : '#fe7c96' }">{{ row.status ? "显示" : "隐藏" }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作">
                    <template slot-scope="{ row }">
                        <div class="_operate">
                            <button @click="openSet(row)">编辑</button>
                        </div>
                        <div class="_delete">
                            <button @click="del(row)">删除</button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 添加或编辑链接弹框 -->
        <el-dialog title="编辑" :visible.sync="dialogShow" width="70%" :show-close="false" :before-close="bfc">
            <el-form :model="form" label-width="90px">
                <el-form-item label="标题">
                    <el-input v-model.trim="form.title"></el-input>
                </el-form-item>
                <el-form-item label="状态">
                    <el-switch
                        class="sw"
                        v-model="form.status"
                        active-color="#7781f1"
                        inactive-color="#ff4949"
                        active-text="显示"
                        inactive-text="隐藏"
                        :active-value="1"
                        :inactive-value="0"
                    ></el-switch>
                </el-form-item>
                <el-form-item label="背景图">
                    <upload-img :src="form.bg_image" @removeSrc="() => (form.bg_image = '')" ref="bg_image"></upload-img>
                </el-form-item>
            </el-form>
            <div v-for="el in form.children" :key="el.id" class="xiang">
                <el-input v-model.trim="el.title" size="small"></el-input>
                <el-switch
                    class="sw"
                    v-model="el.status"
                    active-color="#7781f1"
                    inactive-color="#ff4949"
                    active-text="显示"
                    inactive-text="隐藏"
                    :active-value="1"
                    :inactive-value="0"
                ></el-switch>
                <div>
                    <el-tooltip class="item" effect="dark" content="添加项" placement="top">
                        <i class="el-icon-circle-plus-outline" @click="addItem"></i>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="删除该项" placement="top">
                        <i class="el-icon-circle-close" @click="clearItem(el.id)"></i>
                    </el-tooltip>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogShow = false">取 消</el-button>
                <el-button type="primary" @click="yes">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import axios from "axios";
import uploadImg from "@/components/customs/uploadImg.vue";
import qs from "qs";
export default {
    components: { uploadImg },
    data() {
        return {
            tableData: [],
            form: {},
            dialogShow: false,
        };
    },
    async created() {
        this.tableData = await this.getData();
    },
    methods: {
        async getData() {
            let { data } = await axios.get("/api/video/svpalist");
            return data;
        },
        openAdd() {
            this.form = {
                title: "",
                bg_image: "",
                status: 1,
                children: [
                    {
                        title: "",
                        status: 1,
                        id: Math.random(),
                    },
                ],
            };
            this.dialogShow = true;
        },
        openSet(row) {
            this.form = { ...row };
            this.dialogShow = true;
        },
        async yes() {
            let { form } = this;
            form.bg_image = this.$refs.bg_image.url;
            if (form.id) {
                // 编辑
                var { data } = await axios.put("/api/video/svpafup", qs.stringify(form));
            } else {
                // 添加
                var { data } = await axios.post("/api/video/svpafadd", qs.stringify(form));
            }
            this.$message({
                message: data.msg,
                type: data.code == 200 ? "success" : "error",
            });
            this.tableData = await this.getData();
            this.dialogShow = false;
        },
        async del({ id }) {
            let s = await this.$confirm("是否确认删除?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => true)
                .catch(() => false);
            if (!s) return;
            let { data } = await axios.delete("/api/video/svpafdel", { params: { id } });
            this.$message({
                message: data.msg,
                type: data.code == 200 ? "success" : "error",
            });
            this.tableData = await this.getData();
        },
        addItem() {
            this.form.children.push({
                title: "",
                status: 1,
                id: Math.random(),
            });
        },
        clearItem(id) {
            if (this.form.children.length <= 1) return this.$message.error("请勿删除最后一项！");
            this.form.children = this.form.children.filter(e => e.id != id);
        },
    },
};
</script>

<style lang="less" scoped>
.page {
    .page_content {
        margin-top: 20px;
        .el-table {
            border-radius: 20px;
        }
    }
    .page_pagination {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        /deep/.el-pagination {
            border-radius: 50px;
            overflow: hidden;
            padding: 0px;
        }
    }
    .page_headerSecondary {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
.sw {
    /deep/.el-switch__label.is-active {
        color: #7781f1 !important;
    }
}
.xiang {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    & > div {
        margin-right: 10px;
    }
    .el-input {
        // width: 200px;
        flex: 1;
    }
    i {
        font-size: 24px;
        color: #7781f1;
        cursor: pointer;
    }
}
</style>
